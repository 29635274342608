import { useEffect, useRef } from "react";
import React from 'react';
import { Engine, Scene } from "@babylonjs/core";

function ThreeDScene({ antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, width, height, ...rest}) {
  const reactCanvas = useRef(null);

  // set up basic engine and scene
  useEffect(() => {
    const { current: canvas } = reactCanvas;

    if (!canvas) return;

    const engine = new Engine(canvas, antialias, engineOptions, adaptToDeviceRatio);
    const scene = new Scene(engine, sceneOptions);
    if (scene.isReady()) {
      onSceneReady(scene);
    } else {
      scene.onReadyObservable.addOnce((scene) => onSceneReady(scene));
    }

    engine.runRenderLoop(() => {
      if (typeof onRender === "function") onRender(scene);
      scene.render();
    });

    const resize = () => {
      scene.getEngine().resize();
    };

    if (window) {
      window.addEventListener("resize", resize);
    }

    return () => {
      scene.getEngine().dispose();

      if (window) {
        window.removeEventListener("resize", resize);
      }
    }
  }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady]);

  if (width && height) {
    return <canvas ref={reactCanvas} {...rest} width={width} height={height} />;
  } else {
    return <canvas ref={reactCanvas} {...rest} />;
  }
  
};

export default ThreeDScene;