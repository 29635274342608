// 特定商取引法のための記載
// Specified Commercial Transactions Act

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { 
  Container,
  Box,
  Typography,
  Link
  } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'

function Scta() {
  const { t } = useTranslation()
  const title_type_vat = 'h7'
  const descr_type_vat = 'body1'

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: blueGrey[300]
      },
      background: {
        default: blueGrey[900]
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{
        bgcolor: blueGrey[900],
        mb: 10,
        mt: 10
        }}>
        <Container maxWidth="md">

			    <Box sx={{ 
			      display: 'flex', 
			      flexDirection: 'column', 
			      alignItems: 'center',
			      mt: 1,
			      mb: 10 }}>
		        <Typography 
		          variant="h6"
		          gutterBottom
		          >
		          {t('scta - title')}
		        </Typography>
		      </Box>

	        <Grid container spacing={2} columns={12} rowSpacing={5}>
	          <Grid xs={3}>
	            <Typography 
	              variant={title_type_vat}
	              gutterBottom
	              >
	              事業者
	            </Typography>
	          </Grid>
	          <Grid xs={9}>
	            <Typography 
	              variant={descr_type_vat}
	              gutterBottom
	              >
	              こちらのフォームにメールアドレスを入力いただき、送信していたくと、名前、住所、電話番号、連絡先メールアドレスをご確認いただけます。
	              <Link href="https://forms.gle/J7X9PMJhXZYjkxsp7">https://forms.gle/J7X9PMJhXZYjkxsp7</Link>
	            </Typography>
	          </Grid>

	          <Grid xs={3}>
	            <Typography 
	              variant={title_type_vat}
	              gutterBottom
	              >
	              商品の引渡時期
	            </Typography>
	          </Grid>
	          <Grid xs={9}>
	            <Typography 
	              variant={descr_type_vat}
	              gutterBottom
	              >
	              30日以内にお渡しできない場合は、注文の契約を解消し、返金を行います。基本的に注文の翌日に発送を行いますが、製造者が不在、多忙などの理由からそれができない場合があります。
	            </Typography>
	          </Grid>

	          <Grid xs={3}>
	            <Typography 
	              variant={title_type_vat}
	              gutterBottom
	              >
	              返品
	            </Typography>
	          </Grid>
	          <Grid xs={9}>
	            <Typography 
	              variant={descr_type_vat}
	              gutterBottom
	              >
	              返品はお受けしておりません。このサイトの商品はお客様の注文によって個別の製造を行いますので、返品はお受けしません。これとは別に、商品に契約の内容に適合しない不具合があった場合の、返金を伴う対応は行っております。
	            </Typography>
	          </Grid>
	        </Grid>

        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default Scta;