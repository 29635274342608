import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { 
  Container,
  Box,
  Typography,
  Stack
  } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'

function Requirements() {
  const { t } = useTranslation()
  //const videoRef = useRef(null)

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: blueGrey[300]
      },
      background: {
        default: blueGrey[900]
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{
        bgcolor: blueGrey[900],
        mb: 10,
        mt: 10,
        }}>
        <Container maxWidth="md">
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            position: "absolute",
          }}>
          <Stack spacing={1}>
            <Typography align="left" gutterBottom>
              {t('videos - how-to-flash-program-to-pico')}
            </Typography>
            <video playsInline muted controls>
              <source src="/video/how-to-flash-program-to-pico.webm" type="video/webm" />
            </video>
          </Stack>
        </Box>
      </Container>
      </Box>
    </ThemeProvider>
  )
}

export default Requirements;