import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { 
  Container,
  Box,
  Typography,
  Stack
  } from '@mui/material'
  import Grid from '@mui/material/Unstable_Grid2'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'


function Requirements() {

  const { t } = useTranslation()
  const title_type_vat = 'h6'
  const descr_type_vat = 'body1'

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: blueGrey[300]
      },
      background: {
        default: blueGrey[900]
      }
    }
  })

  const contents = [
    { 
      orderable: true,
      title: t('requirements - pcb - title'),
      description: t('requirements - pcb - description'),
      image: '/requirements/pcb.png',
      image_alt: 'pcb'
    },
    {
      orderable: true,
      title: t('requirements - enclosure - title'),
      description: t('requirements - enclosure - description'),
      image: '/requirements/enclosure.png',
      image_alt: 'enclosure'
    },
    {
      orderable: true,
      title: t('requirements - pinheader - title'),
      description: t('requirements - pinheader - description'),
      image: '/requirements/pinheader.png',
      image_alt: 'pinheader'
    },
    {
      orderable: true,
      title: t('requirements - screws - title'),
      description: t('requirements - screws - description'),
      image: '/requirements/screws.png',
      image_alt: 'screws'
    },
    {
      orderable: false,
      title: t('requirements - keyswitch - title'),
      description: t('requirements - keyswitch - description'),
      image: '/requirements/keyswitchs.png',
      image_alt: 'keyswitch'
    },
    {
      orderable: false,
      title: t('requirements - keycap - title'),
      description: t('requirements - keycap - description'),
      image: '/requirements/keycap.png',
      image_alt: 'keycap'
    },
    {
      orderable: false,
      title: t('requirements - Pico - title'),
      description: t('requirements - Pico - description'),
      image: '/requirements/module-b.png',
      image_alt: 'pico'
    },
    {
      orderable: false,
      title: t('requirements - solder - title'),
      description: t('requirements - solder - description'),
      image: '/requirements/solder-3d.png',
      image_alt: 'solder'      
    },
    {
      orderable: false,
      title: t('requirements - soldering iron - title'),
      description: t('requirements - soldering iron - description'),
      image: '/requirements/soldering-iron-photo-2.png',
      image_alt: 'soldering-iron'
    },
    {
      orderable: false,
      title: t('requirements - screwdriver - title'),
      description: t('requirements - screwdriver - description'),
      image: '/requirements/screwdriver-photo-2.png',
      image_alt: 'driver'
    },
    {
      orderable: false,
      title: t('requirements - pliers - title'),
      description: t('requirements - pliers - description'),
      image: '/requirements/plier-photo-1.png',
      image_alt: 'radiopenchi'
    },
  ]

  function yes() {
    return <CheckIcon color="success" />
  }

  function no() {
    return <CloseIcon color="disabled" />
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{
        bgcolor: blueGrey[900],
        mb: 10,
        mt: 10
        }}>
        <Container maxWidth="md">

        <Stack spacing={2} direction="row" sx={{mb: 5}}>
            {yes()} : 
            <Typography 
              variant="h6"
              gutterBottom
              >
              {t('requirements - orderable products')}
            </Typography>
            {no()} : 
            <Typography 
              variant="h6"
              gutterBottom
              >
              {t('requirements - not orderable products')}
            </Typography>
          </Stack>

          {contents.map((content, index) => (
            <Grid container spacing={2} columns={24}>
              <Grid xs={1}>
                {content.orderable ? yes() : no()}
              </Grid>
              <Grid xs={4}>
                <Typography 
                  variant={title_type_vat}
                  gutterBottom
                  >
                  {content.title}
                </Typography>
              </Grid>
              <Grid xs={13}>
                <Typography 
                  variant={descr_type_vat}
                  gutterBottom
                  >
                  {content.description}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Box
                  component="img"
                  src={content.image}
                  alt={content.image_alt}
                  sx={{
                    width: '300px',          // 幅を300pxに設定
                    height: '200px',         // 高さを200pxに設定
                    objectFit: 'contain', // contain, fill, scaleDown, initial, inherit, and none
                  }}
                />
              </Grid>
            </Grid>
          ))}
        
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Requirements;
