import React from 'react';
import "@babylonjs/loaders/STL"
import { ArcRotateCamera, 
  SceneLoader, 
  Color3, 
  StandardMaterial, 
  HemisphericLight, 
  Vector3,
  Animation,
  AnimationEvent,
  AnimationGroup
  } from "@babylonjs/core"
import * as GUI from "@babylonjs/gui"
import ThreeDScene from "./ThreeDScene"
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'


const framerate = 30
const size = 50
let first = true
let texture = undefined
let solder_telop = undefined
let screw_telop = undefined
let group = undefined
// let playing = false

function Assembly(props) {

  // const [playing, setPlaying] = useState(false)
  const { t } = useTranslation()

  function get_group() {
    if (!group) {
      group = new AnimationGroup("group")
    }
    return group
  }

  function get_ui() {
    if (!texture) {
      texture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI")
      /*
      var panel = new GUI.StackPanel()
      panel.isVertical = false
      panel.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM
      texture.addControl(panel)

      var addButton = function (text, callback) {
        var button = GUI.Button.CreateSimpleButton("button", text)
        button.width = "100px"
        button.height = "30px"
        button.color = "white"
        button.background = "black"
        button.paddingLeft = "10px"
        button.paddingRight = "10px"
        button.top = 270
        button.onPointerUpObservable.add(function () {
          callback()
        })
        panel.addControl(button)
      }

      let _group = get_group()

      addButton("Play", function () {
        console.log("Play")
        console.log(playing)
        //let _playing = !playing
        //setPlaying(_playing)
        playing = !playing
        if (playing) {
          _group.play(true)
        } else {
          _group.pause()
        }
      })
      */
    }
    return texture
  }

  function get_solder_telop() {
    if (!solder_telop) {
      solder_telop = new GUI.TextBlock();
      solder_telop.text = t("Soldering");
      solder_telop.color = "White";
      solder_telop.fontSize = 24;
      //solder_telop.fontWeight = "bold";
      solder_telop.top = 240
    }
    return solder_telop
  }

  function get_screw_telop() {
    if (!screw_telop) {
      screw_telop = new GUI.TextBlock();
      screw_telop.text = t("Tightening Screws");
      screw_telop.color = "White";
      screw_telop.fontSize = 24;
      //screw_telop.fontWeight = "bold";
      screw_telop.top = 240
    }
    return screw_telop
  }

  function setup(scene, meshe, color, rotation, scenario) {
      
    meshe.position.y = scenario[0][1]

    let _group = get_group()
    
    let material = new StandardMaterial("material", scene)
    material.diffuseColor = color
    material.specularColor = new Color3(0.1, 0.1, 0.1)
    meshe.material = material

    const slide_anim = new Animation(
        "slide", 
        "position.y", 
        framerate, 
        Animation.ANIMATIONTYPE_FLOAT, 
        Animation.ANIMATIONLOOPMODE_CYCLE)
    
    const slide_keyframes = []

    for (let kf of scenario) {
      slide_keyframes.push({
        frame: kf[0] * framerate,
        value: kf[1]
      })
    }

    slide_anim.setKeys(slide_keyframes)
    // meshe.animations.push(slide_anim)
    _group.addTargetedAnimation(slide_anim, meshe)

    if (rotation !== 0) {
      const rotate_keyframes = []

      const rotate_anim = new Animation(
        "rotate", 
        "rotation.y", 
        framerate, 
        Animation.ANIMATIONTYPE_FLOAT, 
        Animation.ANIMATIONLOOPMODE_CYCLE
      )

      if (rotation === -1) {
        rotate_keyframes.push({
          frame: 0,
          value: 0,
        })
        rotate_keyframes.push({
          frame: framerate * scenario[1][0],
          value: 0,
        })
        rotate_keyframes.push({
          frame: framerate * scenario[2][0],
          value: Math.PI * 3,
        })
        rotate_keyframes.push({
          frame: framerate * scenario[3][0],
          value: Math.PI * 3,
        })
      } else if (rotation === 1) {
        rotate_keyframes.push({
          frame: 0,
          value: 0,
        })
        rotate_keyframes.push({
          frame: framerate * scenario[1][0],
          value: 0,
        })
        rotate_keyframes.push({
          frame: framerate * scenario[2][0],
          value: - Math.PI * 5,
        })
        rotate_keyframes.push({
          frame: framerate * scenario[3][0],
          value: - Math.PI * 5,
        })
      }
      rotate_anim.setKeys(rotate_keyframes)
      // meshe.animations.push(rotate_anim)
      _group.addTargetedAnimation(rotate_anim, meshe)
    }

    if (first) {
      texture = get_ui()
      solder_telop = get_solder_telop()
      screw_telop = get_screw_telop()
      const solder_telop_show_event = new AnimationEvent(
        framerate * 2,
        function() {
          telop(texture, solder_telop, true)
        },
        false,
      )
      const solder_telop_hide_event = new AnimationEvent(
        framerate * 4,
        function() {
          telop(texture, solder_telop, false)
        },
        false,
      )
      const screw_telop_show_event = new AnimationEvent(
        framerate * 5,
        function() {
          telop(texture, screw_telop, true)
        },
        false,
      )
      const screw_telop_hide_event = new AnimationEvent(
        framerate * 7 - 1,
        function() {
          telop(texture, screw_telop, false)
        },
        false,
      )
      first = false
      slide_anim.addEvent(solder_telop_show_event)
      slide_anim.addEvent(solder_telop_hide_event)
      slide_anim.addEvent(screw_telop_show_event)
      slide_anim.addEvent(screw_telop_hide_event)
    }

    // scene.beginAnimation(meshe, 0, 10 * framerate, true)
  }

  // --------------------- Telop -----------------------------------------

  function telop(texture, telop, show) {
    if (show) {
      texture.addControl(telop)
    } else {
      texture.removeControl(telop)
    }
  }

  // --------------------- Show 3D model----------------------------------
  
  const onSceneReady = async (scene) => {
    const canvas = scene.getEngine().getRenderingCanvas()

    //scene.clearColor = new Color3(255, 255, 255)
  
    const camera = new ArcRotateCamera(
      "camera", 
      - Math.PI / 2, 
      Math.PI / 2.5, 
      10, 
      new Vector3(0, 0, 0),
      scene
    )
    
    camera.mode = camera.ORTHOGRAPHIC_CAMERA;
    
    camera.orthoTop = size
    camera.orthoBottom = - size
    camera.orthoLeft = - size
    camera.orthoRight = size
    
    camera.setPosition(new Vector3(size, size, - size))
    camera.attachControl(canvas, true)
    
    var light_bottom = new HemisphericLight("light", new Vector3(0, -1, 1), scene)
    light_bottom.intensity = 0.5

    var light_top = new HemisphericLight("light", new Vector3(0, 1, -1), scene)
    light_top.intensity = 1

    camera.setTarget(new Vector3(0, 20, 0))

    const keycaps_container = await SceneLoader.LoadAssetContainerAsync("/assembly/keycaps.stl");
    const keyswitchs_container = await SceneLoader.LoadAssetContainerAsync("/assembly/keyswitchs.stl");
    const axis_container = await SceneLoader.LoadAssetContainerAsync("/assembly/axis.stl");
    const keyswitch_pin_container = await SceneLoader.LoadAssetContainerAsync("/assembly/keyswitch_pin.stl");
    const usb_container = await SceneLoader.LoadAssetContainerAsync("/assembly/usb.stl");
    const module_container = await SceneLoader.LoadAssetContainerAsync("/assembly/module.stl");
    const rp2040_container = await SceneLoader.LoadAssetContainerAsync("/assembly/rp2040.stl");
    const tactswitch_container = await SceneLoader.LoadAssetContainerAsync("/assembly/tactswitch.stl");
    const pinheader_pin_container = await SceneLoader.LoadAssetContainerAsync("/assembly/pinheader_pin.stl");
    const pinheader_plastic_container = await SceneLoader.LoadAssetContainerAsync("/assembly/pinheader_plastic.stl");
    const pcb_container = await SceneLoader.LoadAssetContainerAsync("/assembly/pcb.stl");
    const enclosure_container = await SceneLoader.LoadAssetContainerAsync("/assembly/enclosure.stl");
    // const screw_container = await SceneLoader.LoadAssetContainerAsync("/assembly/screw.stl");
    // const nut_container = await SceneLoader.LoadAssetContainerAsync("/assembly/nut.stl");
    const screw_single_container = await SceneLoader.LoadAssetContainerAsync("/assembly/screw_single.stl");
    const nut_single_container = await SceneLoader.LoadAssetContainerAsync("/assembly/nut_single.stl");
    const pinheader_module_solder_container = await SceneLoader.LoadAssetContainerAsync("/assembly/pinheader_module_solder.stl");
    const pinheader_pcb_solder_container = await SceneLoader.LoadAssetContainerAsync("/assembly/pinheader_pcb_solder.stl");
    const keyswitch_pcb_solder_container = await SceneLoader.LoadAssetContainerAsync("/assembly/keyswitch_pcb_solder.stl");
    // const soldering_top_container = await SceneLoader.LoadAssetContainerAsync("/assembly/soldering_top.stl");
    // const soldering_bottom_container = await SceneLoader.LoadAssetContainerAsync("/assembly/soldering_bottom.stl");
    const soldering_arrow_container = await SceneLoader.LoadAssetContainerAsync("/assembly/soldering_arrow.stl");

    const pcb_width = 60
    const pcb_height = 44
    const screw_offset = 2

    const keycaps_meshe = keycaps_container.meshes[0];
    scene.addMesh(keycaps_meshe)
    setup(scene, keycaps_meshe, new Color3(0.8, 0.8, 0.8), 0, [
      [0, 70 + size / 2], 
      [1, -10 + size / 2], 
      [7, -10 + size / 2]
    ]);

    const keyswitchs_meshe = keyswitchs_container.meshes[0];
    scene.addMesh(keyswitchs_meshe)
    setup(scene, keyswitchs_meshe, new Color3(0.3, 0.3, 0.3), 0, [
      [0, 40 + size / 2], 
      [1, -10 + size / 2], 
      [7, -10 + size / 2]
    ]);

    const keyswitch_pin_meshe = keyswitch_pin_container.meshes[0];
    scene.addMesh(keyswitch_pin_meshe)
    setup(scene, keyswitch_pin_meshe, new Color3(0.9, 0.9, 0.0), 0, [
      [0, 40 + size / 2], 
      [1, -10 + size / 2], 
      [7, -10 + size / 2]
    ]);

    const axis_meshe = axis_container.meshes[0];
    scene.addMesh(axis_meshe)
    setup(scene, axis_meshe, new Color3(1.6, 0.1, 0.1), 0, [
      [0, 40 + size / 2], 
      [1, -10 + size / 2], 
      [7, -10 + size / 2]
    ]);

    const usb_meshe = usb_container.meshes[0];
    scene.addMesh(usb_meshe)
    setup(scene, usb_meshe, new Color3(0.6, 0.6, 0.6), 0, [
      [0, 50 + size / 2], 
      [1, -10 + size / 2], 
      [7, -10 + size / 2]
    ]);

    const module_meshe = module_container.meshes[0];
    scene.addMesh(module_meshe)
    setup(scene, module_meshe, new Color3(0.0, 0.5, 0.0), 0, [
      [0, 50 + size / 2], 
      [1, -10 + size / 2], 
      [7, -10 + size / 2]
    ]);

    const rp2040_meshe = rp2040_container.meshes[0];
    scene.addMesh(rp2040_meshe)
    setup(scene, rp2040_meshe, new Color3(0.1, 0.1, 0.1), 0, [
      [0, 50 + size / 2], 
      [1, -10 + size / 2], 
      [7, -10 + size / 2]
    ]);

    const tactswitch_meshe = tactswitch_container.meshes[0];
    scene.addMesh(tactswitch_meshe)
    setup(scene, tactswitch_meshe, new Color3(0.8, 0.8, 0.8), 0, [
      [0, 50 + size / 2], 
      [1, -10 + size / 2], 
      [7, -10 + size / 2]
    ]);

    const pinheader_pin_meshe = pinheader_pin_container.meshes[0];
    scene.addMesh(pinheader_pin_meshe)
    setup(scene, pinheader_pin_meshe, new Color3(0.9, 0.9, 0.0), 0, [
      [0, 25 + size / 2], 
      [1, -9.7 + size / 2], 
      [7, -9.7 + size / 2]
    ]);

    const pinheader_plastic_meshe = pinheader_plastic_container.meshes[0];
    scene.addMesh(pinheader_plastic_meshe)
    setup(scene, pinheader_plastic_meshe, new Color3(0.1, 0.1, 0.1), 0, [
      [0, 25 + size / 2], 
      [1, -10 + size / 2], 
      [7, -10 + size / 2]
    ]);

    const pcb_meshe = pcb_container.meshes[0];
    scene.addMesh(pcb_meshe)
    setup(scene, pcb_meshe, new Color3(0.8, 0.6, 0.3), 0, [
      [0, -10 + size / 2], 
      [7, -10 + size / 2]
    ]);

    const soldering_top_meshe = soldering_arrow_container.meshes[0];
    const soldering_bottom_meshe = soldering_top_meshe.clone("soldering_bottom_meshe")
    soldering_bottom_meshe.rotation.z = Math.PI
    scene.addMesh(soldering_top_meshe)
    scene.addMesh(soldering_bottom_meshe)
    setup(scene, soldering_top_meshe, new Color3(1, 0.5, 0.5), 0, [
      [0, 10000], 
      [2, -2 + size / 2], 
      [4, -3 + size / 2], 
      [7, 10000]
    ]);
    setup(scene, soldering_bottom_meshe, new Color3(1, 0.5, 0.5), 0, [
      [0, 10000], 
      [2, -25 + size / 2], 
      [4, -24 + size / 2], 
      [7, 10000]
    ]);

    const pinheader_module_solder_meshe = pinheader_module_solder_container.meshes[0];
    scene.addMesh(pinheader_module_solder_meshe)
    setup(scene, pinheader_module_solder_meshe, new Color3(1, 0.5, 0.5), 0, [
      [0, 10000], 
      [3, -6.8 + size / 2], 
      [4, -7.2 + size / 2], 
      [7, -7.2 + size / 2]
    ]);

    const pinheader_pcb_solder_meshe = pinheader_pcb_solder_container.meshes[0];
    scene.addMesh(pinheader_pcb_solder_meshe)
    setup(scene, pinheader_pcb_solder_meshe, new Color3(1, 0.5, 0.5), 0, [
      [0, -10000], 
      [3, -12.6 + size / 2], 
      [4, -12.2 + size / 2], 
      [7, -12.2 + size / 2]
    ]);

    const keyswitch_pcb_solder_meshe = keyswitch_pcb_solder_container.meshes[0];
    scene.addMesh(keyswitch_pcb_solder_meshe)
    setup(scene, keyswitch_pcb_solder_meshe, new Color3(1, 0.5, 0.5), 0, [
      [0, -10000], 
      [3, -11 + size / 2], 
      [4, -11 + size / 2], 
      [7, -11 + size / 2]
    ]);

    const enclosure_meshe = enclosure_container.meshes[0];
    scene.addMesh(enclosure_meshe)
    setup(scene, enclosure_meshe, new Color3(0.9, 0.9, 0.8), 0, [
      [0, -10000], 
      [5, -40 + size / 2], 
      [6, -17.2 + size / 2], 
      [7, -17.2 + size / 2]
    ]);
    /*
    const screw_meshe = screw_container.meshes[0];
    scene.addMesh(screw_meshe)
    setup(scene, screw_meshe, new Color3(0.6, 0.6, 0.7), 1, [
      [0, -10000], 
      [5, -50 + size / 2],
      [6, -12.4 + size / 2], 
      [7, -12.4 + size / 2]
    ]);

    const nut_meshe = nut_container.meshes[0];
    scene.addMesh(nut_meshe)
    setup(scene, nut_meshe, new Color3(0.8, 0.8, 0.8), -1, [
      [0, 10000], 
      [5, 50 + size / 2], 
      [6, -9.4 + size / 2], 
      [7, -9.4 + size / 2]
    ]);
    */

    const screw_single_meshe_1 = screw_single_container.meshes[0];
    const screw_single_meshe_2 = screw_single_meshe_1.clone("screw_single_meshe_2");
    const screw_single_meshe_3 = screw_single_meshe_1.clone("screw_single_meshe_3");
    screw_single_meshe_1.position.x = pcb_width / 2 - screw_offset;
    screw_single_meshe_1.position.z = pcb_height / 2 - screw_offset;
    screw_single_meshe_2.position.x = - pcb_width / 2 + screw_offset;
    screw_single_meshe_2.position.z = - pcb_height / 2 + screw_offset;
    screw_single_meshe_3.position.x = pcb_width / 2 - screw_offset;
    screw_single_meshe_3.position.z = - pcb_height / 2 + screw_offset;
    scene.addMesh(screw_single_meshe_1)
    scene.addMesh(screw_single_meshe_2)
    scene.addMesh(screw_single_meshe_3)
    const screw_single_keyframe = [
      [0, -10000], 
      [5, -50 + size / 2],
      [6, -18.2 + size / 2], 
      [7, -18.2 + size / 2]
    ]
    setup(scene, screw_single_meshe_1, new Color3(0.6, 0.6, 0.7), 1, screw_single_keyframe)
    setup(scene, screw_single_meshe_2, new Color3(0.6, 0.6, 0.7), 1, screw_single_keyframe)
    setup(scene, screw_single_meshe_3, new Color3(0.6, 0.6, 0.7), 1, screw_single_keyframe)

    const nut_single_meshe_1 = nut_single_container.meshes[0];
    const nut_single_meshe_2 = nut_single_meshe_1.clone("nut_single_meshe_2");
    const nut_single_meshe_3 = nut_single_meshe_1.clone("nut_single_meshe_3");
    nut_single_meshe_1.position.x = pcb_width / 2 - screw_offset;
    nut_single_meshe_1.position.z = pcb_height / 2 - screw_offset;
    nut_single_meshe_2.position.x = - pcb_width / 2 + screw_offset;
    nut_single_meshe_2.position.z = - pcb_height / 2 + screw_offset;
    nut_single_meshe_3.position.x = pcb_width / 2 - screw_offset;
    nut_single_meshe_3.position.z = - pcb_height / 2 + screw_offset;
    scene.addMesh(nut_single_meshe_1)
    scene.addMesh(nut_single_meshe_2)
    scene.addMesh(nut_single_meshe_3)
    const nut_single_keyframe = [
      [0, 10000], 
      [5, 50 + size / 2], 
      [6, -15 + size / 2], 
      [7, -15 + size / 2]
    ]
    setup(scene, nut_single_meshe_1, new Color3(0.8, 0.8, 0.8), -1, nut_single_keyframe)
    setup(scene, nut_single_meshe_2, new Color3(0.8, 0.8, 0.8), -1, nut_single_keyframe)
    setup(scene, nut_single_meshe_3, new Color3(0.8, 0.8, 0.8), -1, nut_single_keyframe)

    let _group = get_group()
    _group.play(true)
  }
  
  // --------------------------------
  
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      mt: 1 }}>
      <ThreeDScene antialias onSceneReady={onSceneReady} width={600} height={600} />
    </Box>
  )
}

export default Assembly;
