import i18n from "i18next";
import { initReactI18next } from "react-i18next";


const resources = {
  en: {
    translation: {
      "policy - not completed - title": "Finished products cannot be ordered on this website",
      "policy - not completed - description": "To make a complete product, you must assembled by yourself. Assembly requires soldering.",
      "policy - orderable products - title": "Items can be purchased on this website",
      "policy - orderable products - description - pcb": "Circuit board",
      "policy - orderable products - description - enclosure": "optional enclosure",
      "policy - not orderable products - title": "Items cannot be purchased on this website",
      "policy - not orderable products - description - summary": "Required for assembly, but must be obtained if you don't have it.",
      "policy - not orderable products - description - keyswitch": "Cherry MX Compatible Keyswitches",
      "policy - not orderable products - description - keycap": "Cherry MX Compatible Keycaps",
      "policy - not orderable products - description - Raspberry Pi Pico 1": "Raspberry Pi Pico 1",
      "policy - not orderable products - description - soldering iron": "Soldering iron",
      "policy - not orderable products - description - solder": "Solder",
      "policy - not orderable products - description - flax": "Soldering flax",
      "policy - not orderable products - description - philips screwdriver": "Philips screwdriver: for optional enclosure",
      "policy - not orderable products - description - pilers": "Long-nose pilers: for optional enclosure",
      "policy - not orderable products - description - about raspberry bi pico": "Raspberry Pi Pico2024年12月時点のRaspberry Pi Picoの最新モデルは 2です。Raspberry Pi Pico 1 WやRaspberry Pi Pico 2でも動くと思いますが保証しません。",
      "policy - order to ship - title": "How long to ship",
      "policy - order to ship - description": "We do not guarantee the number of days, but basically if there is only one order, we basically ship the next day. If there are multiple orders, we will ship at a rate of 1 order/day.",
      "policy - shipping - title": "Delivering time",
      "policy - shipping - description - summary": "No specific number of days is guaranteed, but this is what the Japan Post website states",
      "policy - shipping - description - how long": "Generally, the package will be delivered the day after the sender's date or the day after.",
      "policy - shipping - description - weekend": "We deliver every day, including Saturdays, Sundays and holidays.",
      "policy - warranty - title": "Warranty period",
      "policy - warranty - description": "For a period of one month from the date the item is shipped. If a board or case ordered from this site breaks under normal use, we will replace it.",
      "policy - assembly - title": "How to make",
      "policy - assembly - description": "We do not provide information to our customers on an individual basis. Please browse our website or consult other websites for more information. If you have any questions or comments, please let us know by contact form.",
      "policy - privacy policy - title": "Privacy policy",
      "policy - privacy policy - description": "The address and phone number you provide when checking out will be used for shipping. The email address will be used to contact you regarding your order.",
      "requirements - requirements": "Required items for assembly",
      "requirements - orderable products": "Items can be purchased on this website",
      "requirements - pcb - title": "Circuit board",
      "requirements - pcb - description": "You can use the Kicad board data that can be downloaded from this site to make boards with CNC milling machine or ordering it from a board fabrication service, or you can order boards from this site. If you order here, that is a CNC milled copper-clad laminated single-sided paper phenolic board, thickness of 1.6mm as materials.",
      "requirements - enclosure - title": "Enclosure",
      "requirements - enclosure - description": "You can 3D print them using enclosure data (STL file) that can be downloaded from the website, or you can order it on this site. The material is PLA.",
      "requirements - pinheader - title": "Pinheader",
      "requirements - pinheader - description": "Used to solder and connect the circuit board to the Raspberry Pi Pico.",
      "requirements - screws - title": "Bolts and nuts",
      "requirements - screws - description": "Used to fasten the enclosure to circuit board.",
      "requirements - not orderable products": "Items cannot be purchased on this website but required for assembly",
      "requirements - keyswitch - title": "Keyswitch",
      "requirements - keyswitch - description": "Cherry MX compatible key switch. Standard type, not low-profile. 3-pin and 5-pin are available, but the board has a hole for 5-pin and it is recommended to use 5-pin. Manufacturers are Gateron, Kailh, etc. other than the original and still good.",
      "requirements - keycap - title": "Keycap",
      "requirements - keycap - description": "Cherry MX compatible keycap. Standard type, not low profile. There are various shapes, Cherry, DSA, XDA, etc., so you can use whatever you like.",
      "requirements - Pico - title": "Pico",
      "requirements - Pico - description": "The Raspberry pi pico series comes in various models. The first Pico was introduced in 2021, followed by the Pico 2 in 2024. The first Pico is now called Pico 1. In addition to Pico 1, there is Pico 1 W, Pico 1 H, and Pico 1 WH. In addition to Pico 2, there is Pico 2 W. This website uses Pico 1.",
      "requirements - solder - title": "Solder",
      "requirements - solder - description": "Solder with a thickness of about 1 mm to 0.5 mm for electronic work. Although lead-free solder is an option for environmental and health reasons, the easiest to work with is low flux-containing solder that contains lead.",
      "requirements - soldering iron - title": "Soldering iron",
      "requirements - soldering iron - description": "For electronic work. For electronic work means the one with a thin tip.",
      "requirements - flux - title": "Flux",
      "requirements - flux - description": "Flux for soldering. When applied to the area to be soldered, it makes it easier for the solder to adhere to the board and terminals, so you are less likely to make mistakes. If you put it on your fingers, it will be sticky forever. Not required.",
      "requirements - pliers - title": "Plier",
      "requirements - pliers - description": "Long-nose pliers. These are useful to hold down the nuts for attaching the case to the board, but you can get by with fingernails. Then again, you don't need them if you don't use the case in the first place.",
      "requirements - screwdriver - title": "Screwdriver",
      "requirements - screwdriver - description": "Phillips screwdriver JIS standard No. 1. Used to turn screws to attach the case to the board. Not necessary if the case is not used.",
      "Videos": "Videos",
      "videos - how-to-flash-program-to-pico": "How to flash programs",
      "Scta": "Legal Notes"
    }
  },
  ja: {
    translation: {
      "any kind": "いろんなキーボードを",
      "designing": "デザインして",
      "generating data": "データを作って",
      "ordering": "注文して",
      "buying parts": "部品を入手して",
      "soldering": "はんだづけして",
      "writing firmware": "ファームウエアを書き込んで",
      "using": "使えるようになります",
      "thank you for watching": "見てくれてありがとう",
      "Select one of the models.": "どちらかのモデルを選んでください",
      "Generating files. It might take about 90 sec.": " ファイルを作っています。90秒ほどかかるかもしれません。",
      "Board": "基板",
      "Keyswitch": "キースイッチ",
      "Keycap": "キーキャップ",
      "Enclosure": "ケース",
      "Module": "モジュール",
      "Assembly": "組み立て",
      "Shipping": "配送",
      "Open for payment and shipping address.": "ボタンを押すと配送先の指定と決済の画面が開きます",
      "Title": "項目",
      "Name": "名前",
      "Price ( JPY )": "価格（円）",
      "Pcs": "個数",
      "Subtotal ( JPY )": "小計（円）",
      "Total": "合計",
      "ABOUT": "このサイトはなに?",
      "Keycaps": "キーキャップ",
      "Keyswitchs": "キースイッチ",
      "Circuit board": "基板",
      "Top": "表",
      "Bottom": "裏",
      "Firmware": "ファームウエア",
      "G-Code": "切削データ",
      "Order": "注文",
      "HOME": "ホーム",
      "EDITOR": "エディター",
      "PROJECT": "プロジェクト",
      "OPTIONS": "オプション",
      "ORDER": "注文",
      "Add": "追加",
      "Delete": "削除",
      "Proceed": "進む",
      "STOP DELETING": "削除を終了",
      "keycode": "キーコード",
      "width": "幅",
      "empty": "空",
      "apply": "適用",
      "Checkout": "チェックアウト",
      "Pay": "支払い",
      "generating": "ファイルが生成されて",
      "Requirements": "必要なもの",
      "Change Firmware": "ファームウエアの変更",
      "Policy": "販売ポリシー",
      "Contact Form": "お問い合わせフォーム",
      "OPEN": "開く",
      "LINKS": "リンク",
      "UPLOAD": "アップロード",
      "DOWNLOAD": "ダウンロード",
      "Soldering": "はんだづけ",
      "Tightening Screws": "ネジ止め",
      "policy - not completed - title": "完成品は注文できません",
      "policy - not completed - description": "キーボードとして完成品にするには、部品を揃えてお客様ご自身で組み立てる必要があります。組み立てにははんだ付けが必要です。",
      "policy - orderable products - title": "このサイトで注文できるもの",
      "policy - orderable products - description - pcb": "基板",
      "policy - orderable products - description - enclosure": "ケース: 注文時に選択した場合のみ",
      "policy - not orderable products - title": "このサイトで注文できないもの",
      "policy - not orderable products - description - summary": "組み立てに必要ですが、お客様がお持ちでない場合、別途入手する必要があります",
      "policy - not orderable products - description - keyswitch": "Cherry MX互換キースイッチ",
      "policy - not orderable products - description - keycap": "Cherry MX互換キーキャップ",
      "policy - not orderable products - description - Raspberry Pi Pico 1": "Raspberry Pi Pico 1",
      "policy - not orderable products - description - soldering iron": "はんだごて",
      "policy - not orderable products - description - solder": "はんだ",
      "policy - not orderable products - description - flax": "はんだづけ用フラックス（無くても良い）",
      "policy - not orderable products - description - philips screwdriver": "プラスドライバー: ケースを付ける場合",
      "policy - not orderable products - description - pilers": "ラジオペンチ: ケースを付ける場合",
      "policy - order to ship - title": "発送まで",
      "policy - order to ship - description": "日数の保証はしませんが、基本的に注文が1件だけなら基本的に翌日出荷します。複数の注文が入っている場合は1件/1日のペースで出荷します。",
      "policy - shipping - title": "届くまで",
      "policy - shipping - description - summary": "特に日数の保証はありませんが、日本郵便のウェブサイトにはこのように記載されています。",
      "policy - shipping - description - how long": "概ね差出日の翌日から翌々日にお届けします。",
      "policy - shipping - description - weekend": "土曜日・日曜日・休日も含めて毎日配達いたします。",
      "policy - warranty - title": "保証期間",
      "policy - warranty - description": "商品を発送から1ヶ月間。通常の使用方法でこのサイトで注文した基板やケースが壊れた場合は交換します。",
      "policy - assembly - title": "作り方",
      "policy - assembly - description": "お客様に個別で情報の提供をすることはありません。当ウェブサイト上を御覧いただいたり、他のサイトなどでお調べください。わかりにくい点があれば、ご意見としてお聞かせいただければ、当サイトの改善のために参考にさせていただきます。",
      "policy - privacy policy - title": "プライバシーポリシー",
      "policy - privacy policy - description": "注文いただいた際に入力いただく住所や電話番号の情報は発送の際に利用します。メールアドレスは注文に関する連絡に利用します。",
      "requirements - requirements": "必要なもの",
      "requirements - orderable products": "注文可能なもの",
      "requirements - pcb - title": "基板",
      "requirements - pcb - description": "サイトからダウンロードできるKicadの基板データを使って、切削したり、基板製造工場に発注して基板を作ることができますが、このサイトで切削した基板を注文することもできます。このサイトで注文できるのは紙フェノールに銅箔された生基板を切削して作ります。",
      "requirements - enclosure - title": "ケース",
      "requirements - enclosure - description": "サイトからダウンロードできるケースのデータ（STLファイル）を使って、3Dプリントすることができますが、このサイトで注文することもできます。材料はPLAです。",
      "requirements - pinheader - title": "ピンヘッダ",
      "requirements - pinheader - description": "基板とRaspberry Piを半田付けして接続するために使います。",
      "requirements - screws - title": "ネジとナット",
      "requirements - screws - description": "ケースと基板をとめるネジとナットです。",
      "requirements - not orderable products": "自分で用意が必要なもの",
      "requirements - keyswitch - title": "キースイッチ",
      "requirements - keyswitch - description": "Cherry MX互換のキースイッチ。ロープロファイルではなく標準のタイプ。3ピンと5ピンがありますが、基板には5ピン用の穴が空いていて、5ピンを使う方がおすすめです。メーカーは本家以外にもGateron, Kailhなどがあってそれでも良いです。",
      "requirements - keycap - title": "キーキャップ",
      "requirements - keycap - description": "Cherry MX互換のキーキャップ。ロープロファイルではなく標準のタイプ。形は様々でCherryとかDSAとかXDAとか色々あるので気に入ったものを使えばよいです。",
      "requirements - Pico - title": "Pico",
      "requirements - Pico - description": "Raspberry pi picoシリースには様々なモデルがあります。2021年に初代のPicoが登場し、2024年にPico 2が登場しました。初代のPicoは現在Pico 1と呼ばれています。Pico 1の他にPico 1 W、Pico 1 H、Pico 1 WHがあります。Pico 2の他にPico 2 Wがあります。このウェブサイトではPico 1を利用します。",
      "requirements - solder - title": "はんだ",
      "requirements - solder - description": "電子工作用の太さ1mmぐらいから0.5mmぐらいのはんだ。環境や健康の面で鉛フリーはんだという選択もありますが、作業しやすいのは鉛を含むフラックス入の低温で溶ける共晶はんだです。",
      "requirements - soldering iron - title": "",
      "requirements - soldering iron - description": "電子工作用のもの。電子工作用というのはつまり先っぽが細いやつです。",
      "requirements - flux - title": "フラックス",
      "requirements - flux - description": "はんだづけ用フラックス。はんだづけする部分に塗ると、はんだが基板や端子に着き易くなるので、失敗しにくくなります。ヤニと呼ばれることもあります。指につけるといつまでもベタベタします。必須ではありません。",
      "requirements - pliers - title": "ラジオペンチ",
      "requirements - pliers - description": "ラジオペンチ。ケースと基板に取り付けるためのナットを押さえるためにあると便利ですが、爪でもなんとかなります。それから、そもそもケースを使わない場合は不要です。",
      "requirements - screwdriver - title": "ドライバー",
      "requirements - screwdriver - description": "プラスドライバー JIS規格 1番。ケースと基板に取り付けるためのネジを回すために使います。ケースを使わない場合は不要です。",
      "Videos": "ビデオ",
      "videos - how-to-flash-program-to-pico": "プログラムの書き込み",
      "Scta": "特定商取引法の情報",
      "scta - title": "特定商取引法の情報"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
